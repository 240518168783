<template>
  <div class="featured-section">
    <div
      v-if="sectionList.length"
      class="featured-section-container"
    >
      <div
        ref="featuredSectionSwiper"
        v-swiper:featured-section="swiperOptions"
        class="featured-section-swiper"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(group, groupIndex) in sectionGroupList"
            :key="groupIndex"
            class="swiper-slide featured-section-swiper-slide"
          >
            <div
              v-for="(service, index) in group"
              :key="index"
              class="featured-section__card"
            >
              <div class="featured-section__image">
                <a
                  class="featured-section__link"
                  @click.stop="(e) => goLink(e, service.LinkUrl)"
                >
                  <img
                    :src="service.IconUrl"
                    :alt="service.Title"
                  >
                </a>
              </div>
              <div class="featured-section__content">
                <h3 class="featured-section__title">
                  {{ service.Title }}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showBannerSwiperArrow"
          class="swiper-pagination featured-section-swiper-pagination"
        />
      </div>
    </div>
    <div
      v-else
      class="featured-section__loading"
    >
      <v-skeleton-loader
        v-for="x in 8"
        :key="x"
        class="homepage-loader__card-container__card"
        type="card"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        pagination: {
          el: '.featured-section-swiper-pagination',
          clickable: true
        }
      },
      activeIndex: 0
    }
  },
  computed: {
    showBannerSwiperArrow () {
      return Math.ceil(this.sectionList.length / 8) > 1
    },
    sectionGroupList () {
      const group = []
      for (let i = 0; i < Math.ceil(this.sectionList.length / 8); i++) {
        group.push(this.sectionList.slice(i * 8, (i + 1) * 8))
      }
      return group
    }
  },
  methods: {
    bannerSlideChangeHandler () {
      const { isBeginning, isEnd, realIndex } = this.$refs.featuredSectionSwiper.swiper
      this.activeIndex = realIndex
      if (isBeginning || isEnd) {
        this.resetSwiper()
      }
    },
    resetSwiper () {
      this.$refs.featuredSectionSwiper?.swiper?.loopDestroy()
      this.$refs.featuredSectionSwiper?.swiper?.loopCreate()
      this.$refs.featuredSectionSwiper?.swiper?.update()
    },
    // slider action
    clickBannerPaginationHandler (index) {
      console.log('clickBannerPaginationHandler', index)
      this.$refs.featuredSectionSwiper?.swiper?.slideToLoop(index, 200, false)
      this.activeIndex = index
    },
    goLink (e, url) {
      e.preventDefault()
      if (url) {
        window.open(url, '_blank')
        this.$gaLogEvent('首頁', `click_${url}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-section {
  display: block;
  overflow: hidden;
  &-container {
    padding: 0 23px;
    margin: 0 -14px;
    ::v-deep {
      .swiper-pagination {
        bottom: 0;
        margin-top: 24px;
        position: static;
        .swiper-pagination-bullet {
          padding: 0;
          width: 8px;
          height: 8px;
          background-color: $lineColor;
          cursor: pointer;
          border-radius: 4px;
          margin-right: 8px;
          margin-left: 0;
          transition: all 0.3s;
          &:first-child {
            border-radius: 4px;
          }
          &:last-child {
            border-radius: 4px;
          }
          &-active {
            background: $mainColor;
            width: 16px;
          }
        }
      }
      .featured-section {
        &-swiper-slide {
          margin-top: 0;
          display: flex;
          flex-wrap: wrap;
        }
        &__card {
          width: 25%;
          padding: 0 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 12px;
          &:nth-of-type(n + 4) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__title {
    font-size: pxTorem(13px);
    font-weight: bold;
    text-align: center;
  }
  &__image {
    max-width: 60px;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__link {
    display: flex;
  }
  &__loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 16px;
    ::v-deep {
      .homepage-loader {
        &__card-container__card:nth-child(n) {
          flex-shrink: 0;
          width: calc(25% - 8px);
          margin-right: 8px;
          .v-skeleton-loader{
            &__image {
              width: 100%;
              max-width: 60px;
              margin: 0 auto;
              aspect-ratio: 1 / 1;
              height: auto;
            }
            &__card-heading {
              .v-skeleton-loader__bone {
                margin: 6px auto 0;
                width: 60%;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
