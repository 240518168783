<template>
  <div
    class="video-list-container card-swiper-container"
    @click="handleVideoClick"
  >
    <div
      v-if="videoList.length"
      ref="videoSwiper"
      v-swiper:video="swiperOptions"
      class="video-list-swiper"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(video, index) in videoList"
          :key="index"
          :class="{ 'shorts' : video.VideoType === 1 }"
          class="swiper-slide video-list-swiper-slide"
        >
          <div class="video-list__card">
            <div
              class="video-list__image"
              :data-video-id="video.videoId"
              :data-video-url="video.VideoUrl"
              :data-type="video.VideoType"
            >
              <v-lazy class="store-lazy">
                <img
                  v-if="video.cover"
                  :src="video.cover"
                  :alt="video.Title"
                  @onerror="video.cover = null"
                >
              </v-lazy>
            </div>
            <div class="video-list__content">
              <h3 class="video-list__title">
                {{ video.Title }}
              </h3>
            </div>
          </div>
        </div>
        <div class="swiper-slide fake-slide" />
      </div>
    </div>
    <div
      v-show="showSwiperArrow && !$store.state.isApp && !isMobile"
      :slot="showSwiperArrow ? 'button-prev' : ''"
      class="swiper-arrow-controls video-list"
    >
      <v-btn
        class="prev btn video-list-swiper-prev-btn"
        fab
      >
        <i class="icon icon-arrow-left-bold" />
      </v-btn>
    </div>
    <div
      v-show="showSwiperArrow && !$store.state.isApp && !isMobile"
      :slot="showSwiperArrow ? 'button-next' : ''"
      class="swiper-arrow-controls video-list"
    >
      <v-btn
        class="next btn video-list-swiper-next-btn"
        fab
      >
        <i class="icon icon-arrow-right-bold" />
      </v-btn>
    </div>
    <ComponentYTPlayer
      :open="dialog.open"
      :video-id="dialog.videoId"
      :is-shorts="dialog.isShorts"
      @close="dialogClose"
      @click.stop
    />
  </div>
</template>

<script>
import ComponentYTPlayer from '@/components/ComponentYTPlayer.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ComponentYTPlayer
  },
  props: {
    videoList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 24,
        loop: false,
        threshold: 2,
        breakpoints: {
          768: {
            spaceBetween: 12
          }
        },
        navigation: {
          nextEl: '.video-list-swiper-next-btn',
          prevEl: '.video-list-swiper-prev-btn'
        }
      },
      activeIndex: 0,
      dialog: {
        open: false,
        videoId: '',
        isShorts: false
      }
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    showSwiperArrow () {
      return this.videoList.length > 1
    }
  },
  methods: {
    handleVideoClick (e) {
      const container = e.target.closest('.video-list__image')
      if (container) {
        const videoUrl = container.getAttribute('data-video-url')
        const videoId = container.getAttribute('data-video-id')
        const type = container.getAttribute('data-type')
        this.dialogOpen({
          videoId,
          type
        })
        this.$gaLogEvent('首頁', `click_影片_${videoUrl}`)
      }
    },
    dialogOpen (video) {
      this.dialog.open = true
      this.dialog.videoId = video.videoId
      this.dialog.isShorts = Number(video.type) === 1
    },
    dialogClose () {
      this.dialog.open = false
      this.dialog.videoId = ''
      this.dialog.isShorts = false
    }
  }
}
</script>

<style lang="scss" scoped>
$sectionMarginXMobile: 16px;
$contentContainerMaxWidth: calc(263px * 4 + 24px * 3);
$contentContainerMaxWidthMargin: calc(263px * 4 + 24px * 3 + 100px);
$contentContainerMaxWidthMobile: calc(263px * 2 + 24px * 1);
.video-list {
  &-container {
    padding: 0;
    &.card-swiper-container {
      margin-bottom: 0;
      ::v-deep {
        .swiper-slide {
          &.fake-slide {
            width: calc(5vw + 12px);
          }
        }
      }
    }
    .swiper-slide {
      width: auto;
      &.shorts {
        .video-list__image {
          aspect-ratio: 9/16;
        }
        .video-list__content {
          max-width: calc(225px * (9 / 16));
        }
      }
    }
  }
  &__image {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    aspect-ratio: 16/9;
    height: 284px;
    position: relative;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 56px;
      height: 56px;
      background: url('~@/assets/image/play.svg') no-repeat;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
    .store-lazy {
      display: flex;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    max-width: calc(225px * (16 / 9));
    padding-top: 8px;
  }
  &__title {
    font-size: pxTorem(17px);
    line-height: 1.4;
  }
}
/* 手機板 */
@media screen and (max-width: $mobileWidth) {
  .video-list {
    &-container.card-swiper-container {
      .swiper-slide {
        margin: 0;
      }
    }
    &__image {
      height: 225px;
    }
    &__title {
      font-size: pxTorem(15px);
    }
    &-container {
      &.card-swiper-container {
        padding-left: $sectionMarginXMobile;
        ::v-deep {
          .swiper-slide {
            transform: none;
            &.fake-slide {
              width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
